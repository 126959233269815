(function($) {

	$.fn.initMenuClick = function() {

		this.click(function() {

			$('#nav-icon2').toggleClass('open');
			$(".c-header").toggleClass("open");
			$(".c-menu").toggleClass("open");
			$(".landing-content").toggleClass("open");

		});

		return this;

	}

})(jQuery);

(function($) {


	$.fn.initHrefClick = function() {

		this.click(function() {

			$(".c-header").removeClass("open");
			$(".c-menu").removeClass("open");
			$('#nav-icon2').removeClass('open');

		});

		return this;

	}

})(jQuery);

$(function() {

	$("#menu-button-responsiv").initMenuClick();
	$("#menu .item").initHrefClick();

	$(document).keyup(function(e) {
		if (e.keyCode == 27) {

			$(".c-header").removeClass("open");
			$(".c-menu").removeClass("open");
			$('#nav-icon2').removeClass('open');
			$('.c-contact-form').removeClass('open');
		}
	});

});
