$(function() {

	$("#navigation").addClass('line--hidden');

	var anchorNames = ['home','proc-my', 'proc-my-1','proc-my-2','sluzby','sluzby-1','sluzby-2', 'sluzby-3', 'reference','o-nas','o-nas-1','o-nas-2','o-nas-3','kontakty'];
	var anchorGroups = {
		'home' : 'home',
		'proc-my': 'proc-my',
		'proc-my-1': 'proc-my',
		'proc-my-2': 'proc-my',
		'sluzby': 'sluzby',
		'sluzby-1': 'sluzby',
		'sluzby-2': 'sluzby',
		'sluzby-3': 'sluzby',
		'reference': 'reference',
		'o-nas': 'o-nas',
		'o-nas-1': 'o-nas',
		'o-nas-2': 'o-nas',
		'o-nas-3': 'o-nas',
		'kontakty': 'kontakty',
	};
	var anchorGroupsInverted = {};

	var activeGroup = '';

	$.each(anchorGroups, function(anchor, group) {
		if (!anchorGroupsInverted[group]) {
			anchorGroupsInverted[group] = [];
		}
		anchorGroupsInverted[group].push(anchor);
	});

	$('#fullpage').fullpage({
		'responsiveWidth': 765,
		'responsiveHeight': 650,
		 //anchors:['','proc-my', 'proc-my','proc-my','sluzby','sluzby','sluzby', 'reference','o-nas','o-nas','o-nas','o-nas','kontakty']
		 anchors: anchorNames,

			onLeave: function(index, nextIndex, direction) {

			var nextAnchor = anchorNames[nextIndex - 1];
			var nextAnchorGroup = anchorGroups[nextAnchor];
			var $navigation = $('#navigation');

			// Menu

			$('#menu .item').not('[data-id="' + nextAnchorGroup + '"]').removeClass('active');
			$('#menu .item[data-id="' + nextAnchorGroup + '"]').addClass('active');

			// Navigation - mezi groupami
			if (nextAnchorGroup != activeGroup) {
				if (anchorGroupsInverted[nextAnchorGroup].length > 1) {
					$navigation.html('');
					$.each(anchorGroupsInverted[nextAnchorGroup], function(index, anchor) {
						var $a = $('<a></a>').addClass('item-circle').attr('href', '#').attr('href', '#' + anchor);
						$navigation.append($a);
					});
					$navigation.removeClass('line--hidden');
				} else {
					$navigation.addClass('line--hidden');
				}
			}

			// Navigation - aktuální puntík
			var indexInGroup = anchorGroupsInverted[nextAnchorGroup].indexOf(nextAnchor);
			$navigation.find('.item-circle').removeClass('on');
			$navigation.find('.item-circle').eq(indexInGroup).addClass('on');


			// Nastavení aktivní groupy
			activeGroup = nextAnchorGroup;

			//alert(""+nextAnchor);

			//Odstranění třídy hide-section
		    $('.section[data-anchor="' + nextAnchor + '"]').removeClass('hide-section');
		    $('.section[data-anchor="' + nextAnchor + '"]').addClass('animation-section');



		}
	});

});
