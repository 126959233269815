$(document).ready(function () {

	var swiper = new Swiper('.swiper-container', {

		nextButton: $('.nextArrow')[0],
		prevButton: $('.prevArrow')[0],
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	type: 'bullets'
		// }
		pagination: '.swiper-pagination',

	});

	$('.reference-all .item').click(
		function() {
			swiper.slideTo($(this).attr('data-index'));
		}
	);

	//swiper.on('slideChange', function () {
	//
	//});
});


