(function($) {

	$.fn.initFormClick = function() {

		this.click(function() {

			$('.c-contact-form').addClass('open');

		});

		return this;

	}

})(jQuery);

(function($) {

	$.fn.initCloseClick = function() {

		this.click(function() {

			$('.c-contact-form').removeClass('open');

		});

		return this;

	}

})(jQuery);


$(function() {

	$(".close").initCloseClick();
	$(".button-form").initFormClick();
	

});


